import { createAsyncThunk } from '@reduxjs/toolkit';
import { PlaidApi, PlaidRequestParams, SetBankAccountsError } from 'api/PlaidApi';

export const createSetBankAccountsThunk = (api: PlaidApi) =>
  createAsyncThunk('plaid/setBankAccounts', async (payload: PlaidRequestParams, thunkApi) => {
    try {
      await api.setBankAccounts(payload.applicationId!, payload);
    } catch (error: any) {
      return thunkApi.rejectWithValue({ status: error.responseStatus, ...error.data } as SetBankAccountsError);
    }
  });

export default createSetBankAccountsThunk;
