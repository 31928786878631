import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';

import { FlowComponentType } from 'routes/types';
import { CardSummaryResult } from 'enums/FlowNextResults';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useQueryParams } from 'hooks/useQueryParam';

import { getCardData } from 'selectors/getCardData';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getYourNameData } from 'selectors/yourName';
import { getYourContactData } from 'selectors/getYourContact';
import { getProfessionGroup } from 'selectors/professionGroup';
import { getAuthData } from 'selectors/getAuthData';
import { getYourIncome } from 'selectors/yourIncome';

import { joinCardWaitList, joinCardWaitListWithLoanOffer } from 'thunks';

import { ConversionType, trackConversion, trackConversionLead } from 'utils/analytics';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import { getClientTimezone } from 'utils/dateUtils';

import { setCardData } from 'handlers/cardData';
import RequestError from 'errors/RequestError';

import CardSummaryDetails from './components/CardSummaryDetails';
import CardBenefits from './components/CardBenefits';

import styles from './Summary.module.scss';

const Summary = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application: loanOfferApplication } = useSelector(getApplicationData);
  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const {
    not_employed: notEmployed,
    total_annual_income: totalAnnualIncome,
    start_of_employment: startOfEmployment,
    employer_name: employerName,
    credit_score_range: creditScoreRange,
  } = useSelector(getYourIncome);
  const { isLoading } = useSelector(getCardData);

  const { professionGroup } = useSelector(getProfessionGroup);
  const {
    cardColor,
    borrowerCredentials,
    initialReferrer,
    referredBy: cardReferredBy,
    applied,
    smartSpendingLimitsEnabled,
    smartAutoPayEnabled,
    importDebtEnabled,
  } = useSelector(getCardData);
  const { partnerId: partnerName } = useSelector(getPreQualificationData);
  const { sessionToken } = useSelector(getAuthData);

  const handleNotNow = () => {
    analytics.track('Card Waitlist Action', { joined: false });
    handleNext(CardSummaryResult.NotNow);
  };

  const handleJoinWaitlist = async () => {
    if (applied) {
      handleNext(CardSummaryResult.Done);
      return;
    }
    let cardAppliedResponse;
    try {
      if (loanOfferApplication) {
        cardAppliedResponse = await dispatchWithUnwrap(
          joinCardWaitListWithLoanOffer({
            applicationId: loanOfferApplication.id,
            httpReferrer: initialReferrer,
            cardReferredBy,
            smartSpendingLimitsEnabled,
            smartAutoPayEnabled,
            importDebtEnabled,
          }),
        );
      } else {
        cardAppliedResponse = await dispatchWithUnwrap(
          joinCardWaitList({
            firstName,
            lastName,
            professionGroup,
            credentials: borrowerCredentials!,
            email,
            phoneNumber,
            totalAnnualIncome: totalAnnualIncome ?? undefined,
            notEmployed,
            employment: [
              {
                employerName,
                hireDate: startOfEmployment ?? undefined,
              },
            ],
            creditScoreRange: creditScoreRange ?? undefined,
            cardColor: cardColor!,
            utm: getUtmTagsVariables(params),
            httpReferrer: initialReferrer,
            cardReferredBy,
            timezone: getClientTimezone(),
            resumeLink: `${window.location.href}`,
            partnerId: partnerName,
            smartSpendingLimitsEnabled,
            smartAutoPayEnabled,
            importDebtEnabled,
            sessionToken,
          }),
        );
      }
    } catch (error) {
      if ((error as RequestError<unknown>).responseStatus === 401) {
        handleNext(CardSummaryResult.SessionExpired);
      } else {
        handleNext(CardSummaryResult.Error);
      }
      return;
    }

    if (cardAppliedResponse.applied) {
      dispatch(setCardData(cardAppliedResponse));
      handleNext(CardSummaryResult.Done);
    }

    if (cardAppliedResponse.borrowerId) {
      analytics.identify(cardAppliedResponse.borrowerId);
      (window as any).nid('setUserId', cardAppliedResponse.borrowerId);
    }

    trackConversionLead({
      email,
      firstName,
      lastName,
      phoneNumber,
    });

    analytics.track('Card Waitlist Action', { joined: true });
    trackConversion(ConversionType.CardJoinedWaitlist);

    handleNext(CardSummaryResult.Done);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Plannery Card Summary">
        <CardSummaryDetails />

        <CardBenefits />

        <div className={styles.buttonsContainer}>
          <Button type={ButtonType.Primary} onClick={handleJoinWaitlist} isLoading={isLoading}>
            Join Waitlist
          </Button>
          {!isLoading && (
            <Button type={ButtonType.Transparent} onClick={handleNotNow}>
              Not Now
            </Button>
          )}
        </div>
      </FormContainer>
      <div className={styles.disclaimerContainer}>
        <p>
          ¹ Total credit and APR are based on Plannery’s personal lending terms. Terms are subject to change and
          qualification.
        </p>
        <p>² Estimated savings is based on expected usage over 12 months.</p>
      </div>
    </>
  );
};

export default Summary;
