import React from 'react';
import clsx from 'clsx';

import { useFinancialCheckupData } from 'components/FinancialCheckup/YourFinances/yourFinancesUtils';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import DebtCategories from './DebtCategories';

import styles from './Debts.module.scss';

interface DebtsProps {
  joinCategories?: boolean;
  showTotalDebt?: boolean;
}

const Debts = ({ joinCategories = false, showTotalDebt = false }: DebtsProps) => {
  const { keepItDebts, payOffDebts, shouldOfferPlanneryLoan, totalDebt } = useFinancialCheckupData();

  if (keepItDebts.length === 0 && payOffDebts.length === 0 && !(showTotalDebt && totalDebt)) {
    return null;
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.join]: joinCategories,
      })}
    >
      {showTotalDebt && (
        <p className={styles.totalDebtLabel}>
          <span>Your Total Debt is:</span>
          <span className={styles.totalDebtAmount}>{formatMonetaryAmount(totalDebt)}</span>
        </p>
      )}

      {keepItDebts.length > 0 &&
        maybeWrapInCard(
          <DebtCategories title="These Categories Look OK" debtItems={keepItDebts} totalDebt={totalDebt} />,
          !joinCategories,
        )}

      {keepItDebts.length > 0 && payOffDebts.length > 0 && joinCategories && <div className={styles.separator} />}

      {payOffDebts.length > 0 &&
        maybeWrapInCard(
          <DebtCategories
            icon="warning"
            title={shouldOfferPlanneryLoan ? 'You’re Being Overcharged!' : 'Consider paying these off ASAP'}
            debtItems={payOffDebts}
            totalDebt={totalDebt}
          />,
          !joinCategories,
        )}
    </div>
  );
};

const maybeWrapInCard = (element: JSX.Element, wrap: boolean): JSX.Element => {
  if (wrap) {
    return <div className={styles.card}>{element}</div>;
  }
  return element;
};

export default Debts;
