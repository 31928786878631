import { NO_OFFER_STATUSES } from 'constants/offer';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import {
  Debt,
  DebtName,
  DebtRecommendation,
  DebtSummary,
  LoanTypeHumanized,
  Tradeline,
} from 'handlers/applicationData';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

export interface Category {
  title: string;
  apr: number;
  accounts: Account[];
}

export interface Account {
  logo: string;
  name: string;
  amount: number;
}

export const REVIEW_STATUSES = [
  ApplicationStatusName.ManualReview,
  ApplicationStatusName.ManualVerify,
  ApplicationStatusName.FinalReview,
];

const debtColor: { [key in LoanTypeHumanized]: string } = {
  [LoanTypeHumanized.PersonalLoan]: '#D77EF6',
  [LoanTypeHumanized.CreditCard]: '#86B4F9',
  [LoanTypeHumanized.MedicalLoan]: '#ED69C0',
  [LoanTypeHumanized.StudentLoan]: '#7D89FA',
  [LoanTypeHumanized.AutoLoan]: '#49DED5',
  [LoanTypeHumanized.Mortgage]: '#ED69C0',
  [LoanTypeHumanized.Other]: '#ED69C0',
};

export const getTotalAmountForCategory = (tradelines: Tradeline[]): number =>
  tradelines.reduce((total: number, tradeline) => total + tradeline.balance, 0);

export const getSparkLineColor = (debtName: LoanTypeHumanized): string => debtColor[debtName];

interface DebtCategory {
  payOffDebts: Debt[];
  keepItDebts: Debt[];
}

export const useFinancialCheckupData = () => {
  const { application } = useSelector(getApplicationData);
  const { eligible } = useSelector(getStudentLoanData);

  const shouldOfferPlanneryLoan = !NO_OFFER_STATUSES.includes(application?.status as ApplicationStatusName);
  const debtSummary = application?.debtSummary as DebtSummary | undefined;
  const totalDebt = debtSummary
    ? (Object.values(debtSummary) as Debt[]).reduce((total, debt) => total + (debt.totalBalance ?? 0), 0)
    : undefined;

  const { payOffDebts, keepItDebts } = getDebtCategories(debtSummary, eligible);

  return {
    totalDebt,
    payOffDebts,
    keepItDebts,
    shouldOfferPlanneryLoan,
  };
};

export const getDebtCategories = (debtSummary?: DebtSummary, studentLoanEligible?: boolean): DebtCategory => {
  const payOffDebts: Debt[] = [];
  const keepItDebts: Debt[] = [];

  debtSummary &&
    Object.values(debtSummary).forEach((debt: Debt) => {
      if (
        debt.recommendation === DebtRecommendation.PayOff ||
        (debt.name === DebtName.StudentLoan && studentLoanEligible)
      ) {
        payOffDebts.push(debt);
      } else {
        keepItDebts.push(debt);
      }
    });

  return {
    payOffDebts,
    keepItDebts,
  };
};

export const isWithinAnHour = (date: Date): boolean => {
  const now = new Date();
  const diffTime = Math.abs(now.getTime() - date.getTime());
  const diffHours = diffTime / (1000 * 60 * 60);
  return diffHours <= 1;
};
