import React from 'react';

import { NavigateOptions } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { HardOfferData, StudentLoanAssistanceAppointment } from 'handlers/applicationData';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import { ReactComponent as AdditionalFundsIcon } from 'images/action-items/additional-funds.svg';
import { ReactComponent as SignLoanAgreementIcon } from 'images/action-items/sign-agreement.svg';
import { ReactComponent as SetupRepaymentIcon } from 'images/action-items/setup-repayment.svg';
import { ReactComponent as VerifyEmploymentIcon } from 'images/action-items/suitcase.svg';
import { ReactComponent as StudentLoanIcon } from 'images/action-items/student-loan.svg';
import { ReactComponent as PlanneryCardIcon } from 'images/action-items/credit-card.svg';
import { ReactComponent as ReferralProgramIcon } from 'images/action-items/spread-word.svg';
import { ReactComponent as DebtConsolidationIcon } from 'images/action-items/down-trend.svg';
import { ReactComponent as BankIcon } from 'images/action-items/bank.svg';
import { ReactComponent as IdentityIcon } from 'images/action-items/identity.svg';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { getStudentLoanStart } from 'routes/StudentLoanForgivenessRoutes';
import { useApplySteps } from 'components/StudentLoanForgiveness/Apply/applySteps';
import { useVerificationSteps, VerificationStep } from 'components/Verification/verificationSteps';
import { PartnerData, usePartnerData } from 'enums/PartnerName';
import { formatNumber } from 'utils/formatNumber';
import { getTypeOfLoan } from 'utils/getTypeOfLoan';

enum VerificationStepName {
  Employment = 'Verify Employment',
  BankAccount = 'Provide Bank Account',
  KYC = 'Verify Identity',
}

interface VerificationStepItem {
  icon: React.FunctionComponent;
  name: VerificationStepName;
}

export interface ActionItem {
  id: string;
  name: string;
  description: JSX.Element;
  icon: React.FunctionComponent<{ className?: string }>;
  route: RoutePath;
  visible: boolean;
  routeOptions?: NavigateOptions;
}

interface ItemsData {
  moneySaved?: number;
  monthsSaved?: number;
  loanAmount?: number;
  maxLoanAmount?: number;
  additionalFundsEligibility: boolean;
  additionalFundsInterestDifference: number;
  payOff?: number;
  notEmployed?: boolean;
  studentLoanEligible: boolean;
  studentLoansBalance?: number;
  studentLoanAssistanceInProgress: boolean;
  studentLoanAssistanceAppointment?: StudentLoanAssistanceAppointment;
  waitlistPosition?: number;
  waitlistLength?: number;
  cardCashbackPercentage: number;
  cardApplied: boolean;
  applicationStatus?: ApplicationStatusName;
  partnerData: PartnerData;
  verificationStepData: VerificationStepItem;
  showPaystubs?: boolean;
}

export const useActionItems = () => {
  const { totalBalance, eligible } = useSelector(getStudentLoanData);
  const { waitListPosition, waitListLength, applied: cardApplied } = useSelector(getCardData);
  const { application } = useSelector(getApplicationData);
  const { stepsStarted } = useApplySteps();
  const { firstUncompletedStep } = useVerificationSteps();

  const { status, studentLoanAssistanceAppointment } = application ?? {};
  const hardOffer = application?.hardOffer as HardOfferData | undefined;

  const partnerData = usePartnerData();

  const additionalFundsInterestDifference =
    !!hardOffer?.planneryLoan.apr && !!hardOffer?.additionalFundsTotal.apr
      ? (hardOffer?.additionalFundsTotal.apr / hardOffer?.planneryLoan.apr - 1) * 100
      : 0;

  const getVerificationStepItem = (): VerificationStepItem => {
    switch (firstUncompletedStep) {
      case VerificationStep.EmploymentDetails:
      case VerificationStep.UploadPaystubs:
        return { name: VerificationStepName.Employment, icon: VerifyEmploymentIcon };
      case VerificationStep.CheckingAccount:
        return { name: VerificationStepName.BankAccount, icon: BankIcon };
      default:
        return { name: VerificationStepName.KYC, icon: IdentityIcon };
    }
  };

  return actionItems({
    ...hardOffer?.offerSummary,
    notEmployed: application?.notEmployed,
    studentLoanEligible: eligible === true,
    studentLoansBalance: totalBalance,
    studentLoanAssistanceInProgress: stepsStarted,
    studentLoanAssistanceAppointment,
    waitlistPosition: waitListPosition,
    waitlistLength: waitListLength,
    cardCashbackPercentage: 5,
    cardApplied: cardApplied ?? false,
    applicationStatus: status,
    partnerData,
    additionalFundsEligibility: application?.additionalFundsEligibility ?? false,
    additionalFundsInterestDifference,
    payOff: hardOffer?.payOffTotal.amount,
    maxLoanAmount: application?.maxLoanAmount,
    verificationStepData: getVerificationStepItem(),
  });
};

const timeSavedLabel = (prefix: string, monthsSaved?: number) => {
  if (!monthsSaved || monthsSaved <= 0) {
    return null;
  }

  const timeText =
    monthsSaved >= 36
      ? `${Math.floor(monthsSaved / 12)} years earlier`
      : `${monthsSaved} month${monthsSaved > 1 && 's'} earlier`;

  return (
    <>
      {' '}
      {prefix}
      <strong> {timeText}</strong>
    </>
  );
};

export const actionItems = ({
  moneySaved,
  monthsSaved,
  loanAmount,
  additionalFundsEligibility,
  additionalFundsInterestDifference,
  payOff,
  maxLoanAmount,
  notEmployed,
  studentLoanEligible,
  studentLoansBalance,
  studentLoanAssistanceInProgress,
  studentLoanAssistanceAppointment,
  waitlistPosition,
  cardCashbackPercentage,
  cardApplied,
  applicationStatus,
  verificationStepData,
}: ItemsData) => {
  const studentLoanDataState = useSelector(getStudentLoanData);

  const items = [
    {
      id: 'signLoanAgreement',
      name: 'Sign Loan Agreement',
      description: (
        <span>
          You're almost at the finish line!{' '}
          {!payOff ? (
            <>
              Plannery can save you <strong>{formatMonetaryAmount(loanAmount)}</strong> compared to the average personal
              loan.
            </>
          ) : (
            <>
              Plannery can save you <strong>{formatMonetaryAmount(moneySaved)}</strong> and help you pay off{' '}
              {formatMonetaryAmount(payOff)}
              {timeSavedLabel('in personal debt', monthsSaved)}!
            </>
          )}
        </span>
      ),
      icon: SignLoanAgreementIcon,
      route: RoutePath.DocuSignSignature,
      visible: applicationStatus === ApplicationStatusName.SigningAgreement,
    },
    {
      id: 'setUpRepayment',
      name: 'Set Up Repayment',
      icon: SetupRepaymentIcon,
      description: (
        <span>
          You're close to saving! Plannery can save you <strong>{formatMonetaryAmount(moneySaved)}</strong> and help you
          pay off {formatMonetaryAmount(loanAmount)}
          {timeSavedLabel('in personal debt', monthsSaved)}!
        </span>
      ),
      route: RoutePath.RepaymentMethod,
      visible: applicationStatus === ApplicationStatusName.SetUpDeduction,
    },
    {
      id: 'verificationStep',
      name: verificationStepData.name,
      icon: verificationStepData.icon,
      description: (
        <span>
          {verificationStepData.name === VerificationStepName.BankAccount
            ? 'Provide your bank account details'
            : 'We need to verify some information'}{' '}
          so Plannery can save you <strong>{formatMonetaryAmount(moneySaved)}</strong> and help you pay off{' '}
          {formatMonetaryAmount(loanAmount)}
          {timeSavedLabel('in personal debt', monthsSaved)}!
        </span>
      ),
      route: RoutePath.SetupAccount,
      visible: applicationStatus === ApplicationStatusName.Verification,
    },
    {
      id: 'debtConsolidation',
      name: 'Reduce High Interest Debt',
      description: (
        <span>
          Plannery can save you <strong>{formatMonetaryAmount(moneySaved)}</strong> and help you pay off{' '}
          {formatMonetaryAmount(payOff)} in personal debt
          {timeSavedLabel('', monthsSaved)}!
        </span>
      ),
      icon: DebtConsolidationIcon,
      route: RoutePath.YourSavings,
      visible: applicationStatus === ApplicationStatusName.OfferAvailable && payOff !== undefined && payOff > 0,
    },
    {
      id: 'studentLoanForgiveness',
      name: 'Get Student Loans Forgiven',
      description: (
        <span>
          Your <strong>{formatMonetaryAmount(studentLoansBalance)}</strong> in federal student loans likely qualifies
          for assistance!
        </span>
      ),
      icon: StudentLoanIcon,
      route: getStudentLoanStart(studentLoanDataState),
      visible: studentLoanEligible && !studentLoanAssistanceAppointment && !studentLoanAssistanceInProgress,
    },
    {
      id: 'planneryCard',
      name: 'Join the Plannery Card Waitlist',
      description: (
        <span>
          Exceptional care deserves exceptional perks: Earn up to <strong>{cardCashbackPercentage}% back</strong> on
          your purchases and pay <strong>90% less interest</strong> than the average credit card.
        </span>
      ),
      icon: PlanneryCardIcon,
      route: RoutePath.BuildCreditAvoidDebtSaveMoney,
      visible: !cardApplied,
    },
    {
      id: 'additionalFunds',
      name: `Save with a Healthcare ${getTypeOfLoan(notEmployed)} Loan`,
      description: (
        <span>
          Borrow up to <strong>{formatMonetaryAmount(maxLoanAmount)}</strong> with Plannery
          {additionalFundsInterestDifference > 0 ? (
            <>
              {' at interest rates '}
              {additionalFundsInterestDifference >= 20 ? (
                <strong>{formatNumber(additionalFundsInterestDifference)}%</strong>
              ) : null}
              {' below the market average.'}
            </>
          ) : null}
        </span>
      ),
      icon: AdditionalFundsIcon,
      route: RoutePath.YourSavings,
      visible: applicationStatus === ApplicationStatusName.OfferAvailable && additionalFundsEligibility,
    },
    {
      id: 'referralProgram',
      name: 'Spread The Word!',
      description: (
        <span>
          You're<strong>{waitlistPosition ? ` #${waitlistPosition}` : ''}</strong> on the waitlist for Plannery Card.
          Spread the word to <strong>help us launch!</strong>
        </span>
      ),
      icon: ReferralProgramIcon,
      route: RoutePath.ReferralProgram,
      routeOptions: { state: { fromYourFinances: true } },
      visible: cardApplied,
    },
  ];

  return {
    actionItems: items,
    nextRoute: getFirstVisibleAction(items)?.route,
  };
};

const getFirstVisibleAction = (items: ActionItem[]) => items.find((item) => item.visible);
