import Api, { FetchMethodType } from './Api';

export interface PlaidRequestParams {
  applicationId: string;
  publicToken: string;
}

export interface PlaidLinkToken {
  LINK_TOKEN: string;
}

export interface BankAccountInfo {
  id: string;
  name: string;
  officialName: string | null;
  mask: string;
  balance: number;
}
export interface PlaidApi {
  getLinkToken(applicationId: string): Promise<PlaidLinkToken>;
  setBankAccounts(appId: string, params: PlaidRequestParams): Promise<void>;
  getCheckingAccounts(appId: string, plaidToken: string): Promise<BankAccountInfo[]>;
  finishIncomeVerification(appId: string): Promise<void>;
}

export enum SetBankAccountsErrorType {
  AccountOwnerMismatchError = 'AccountOwnerMismatchError',
}

export type SetBankAccountsError = {
  status: number;
  error: string;
  errorType: SetBankAccountsErrorType;
};

export default class PlaidRestApi extends Api implements PlaidApi {
  public async getLinkToken(applicationId: string) {
    const response = await this.fetch<PlaidLinkToken>(`/plaid/linkToken`, {
      method: FetchMethodType.POST,
      body: {
        applicationId,
      },
    });
    return response;
  }

  public async setBankAccounts(appId: string, body: PlaidRequestParams) {
    await this.fetch(`/plaid/bankAccounts/${appId}`, {
      method: FetchMethodType.POST,
      body,
    });
  }

  public async getCheckingAccounts(appId: string, plaidToken: string) {
    const response = await this.fetch<BankAccountInfo[]>(`/plaid/bankAccounts/${appId}?token=${plaidToken}`, {
      method: FetchMethodType.GET,
    });
    return response;
  }

  public async finishIncomeVerification(appId: string) {
    await this.fetch(`/plaid/finishIncomeVerification/${appId}`, {
      method: FetchMethodType.PUT,
    });
  }
}
