import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FlowComponentType } from 'routes/types';
import { setApplicationFlow } from 'handlers/applicationStep';

import Button, { ButtonType } from 'components/Button';
import FormNavigation from 'components/FormNavigation/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import { ApplyEmployeeLoanResult } from 'enums/FlowNextResults';
import { CurrentFlow } from 'enums/CurrentFlow';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { getAuthData } from 'selectors/getAuthData';
import { getYourNameData } from 'selectors/yourName';
import { getYourContactData } from 'selectors/getYourContact';
import { getCardData } from 'selectors/getCardData';
import { getYourIncome } from 'selectors/yourIncome';
import { authSessionThunk } from 'thunks';
import { getTypeOfLoan } from 'utils/getTypeOfLoan';

import { ReactComponent as TitleIcon } from 'images/offer-available-icon.svg';

import styles from './ApplyEmployeeLoan.module.scss';

const ApplyEmployeeLoan = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applied, importDebtEnabled } = useSelector(getCardData);
  const { not_employed: notEmployed } = useSelector(getYourIncome);

  const { phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { sessionToken } = useSelector(getAuthData);

  const handleNoThanks = () => {
    handleNext(applied ? ApplyEmployeeLoanResult.ThanksForJoining : undefined);
  };

  const handleApply = () => {
    if (sessionToken) {
      // this is needed to run credit check for a future step only for debt consolidation, will wait for this to resolve when it's needed
      dispatchWithUnwrap(
        authSessionThunk({
          phoneNumber,
          firstName,
          lastName,
          sessionToken,
        }),
      );
    }
    dispatch(setApplicationFlow({ currentFlow: CurrentFlow.DebtConsolidation }));
    handleNext(ApplyEmployeeLoanResult.Apply);
  };

  const loanName = `Healthcare ${getTypeOfLoan(notEmployed)} Loan`;
  const crossSellProductDescription = importDebtEnabled ? (
    <>consolidate your balances with a {loanName}</>
  ) : (
    <>
      access up to a <span className={styles.titleHighlight}>$15,000</span> {loanName}
    </>
  );

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<TitleIcon />}
        title={
          <>
            {applied ? 'While you wait, want' : 'Want'} to {crossSellProductDescription}?
          </>
        }
        subtitle="Access below market interest rates exclusively for healthcare professionals."
      >
        <div className={styles.disclaimerContainer}>
          By clicking Apply, you’ll begin a loan application. See{' '}
          <a href="https://www.planneryapp.com/lending/nurses" target="_blank" rel="noreferrer">
            Product Details
          </a>{' '}
          to learn more.
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={handleApply}>Apply</Button>
          <Button type={ButtonType.Inverted} onClick={handleNoThanks}>
            No Thanks
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default ApplyEmployeeLoan;
